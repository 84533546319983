export default [
  {icon: 'desktop', auth: 'admin', title: '工作台', path: '/home'},
  {
    icon: 'ordered-list', auth: 'shop',  title: '订单管理', child: [
      {title: '服务订单', path: '/order/list'},
      {title: '商品订单', path: '/order/goods'},
    ]
  },
  {
    icon: 'appstore', auth: 'admin', title: '服务管理', child: [
      {title: '服务类目', path: '/service/sku'},
    ]
  },
  {
    icon: 'shop', auth: 'goods.goods.view', title: '商品管理', child: [
      {title: '商品列表', auth: 'goods.goods.view', path: '/goods/'}
    ]
  },
  {icon: 'red-envelope', auth: 'admin', title: '优惠券管理', path: '/coupon'},
  {icon: 'picture', auth: 'admin', title: 'Banner管理', path: '/banner'},
  {icon: 'pay-circle', auth: 'admin', title: '财务管理', child: [
    // {title: '账单总览', path: '/finance/overview' },
    {title: '对账单明细', path: '/finance/detail' },
  ]},
  {
    icon: 'wechat', auth: 'admin', title: '微信管理', child: [
      {title: '微信用户', path: '/wechat/user'},
    ],
  },
  {
    icon: 'setting', title: '用户管理', auth: "system.account.view|system.role.view", child: [
      {title: '账户管理', auth: 'system.account.view', path: '/system/account'},
      {title: '角色管理', auth: 'system.role.view', path: '/system/role'},
    ]
  },
]
