import React from 'react';
import {observer} from 'mobx-react';
import {Table, Modal, message, Tag} from 'antd';
import BannerForm from './Form';
import http from '../../libs/http';
import store from './store';
import styles from './index.module.css';

@observer
class SkuTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [ {
    title: 'banner名称',
    dataIndex: 'name',
  }, {
    title: 'banner分类',
    dataIndex: 'type',
  },{
    title: '图片',
    render (info) {
      if (info['img_url']){
        return <div className={styles.tableImg}>
          <img src={info['img_url']} alt="avatar" />
        </div>
      } else {
        return ''
      }
    }
  }, {
    title: '链接地址',
    dataIndex: 'img_href',
  }, {
    title: '备注',
    dataIndex: 'remark',
  },{
    title: '显示顺序',
    dataIndex: 'sort',
  },{
    title: '状态',
    render: text => {
      if (text['status']) {
        return <Tag color="#87d068" style={{cursor: 'pointer'}} onClick={() => this.handleStatus(text)}>正常</Tag>
      }
      else {
        return <Tag color="#f50" style={{cursor: 'pointer'}} onClick={() => this.handleStatus(text)}>已下架</Tag>
      }
    }

  }, {
    title: '操作',
    render: info => (
        <span>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
      </span>
    )
  }];

  handleDelete = (text) => {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${text['name']}】?`,
      onOk: () => {
        return http.delete(`/api/service/sku/${text.id}/`)
            .then(() => {
              message.success('删除成功');
              store.fetchRecords()
            })
      }
    })
  };


  handleStatus = (text) => {
    console.log('text111', text);
    Modal.confirm({
      title: '修改状态',
      content: `确定要修改【${text['name']}】的状态?`,
      onOk: () => {
        text['status'] = !text['status'];
        return http.put(`/api/banner/info/${text.id}/`, text)
            .then(() => {
              message.success('状态修改成功');
              store.fetchRecords()
            })
      }
    })
  };

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(item => String(item['status']) === store.f_status)
    }

    return (
        <React.Fragment>
          <Table rowKey="id" loading={store.isFetching} dataSource={data} columns={this.columns}/>
          {store.formVisible && <BannerForm />}
        </React.Fragment>
    )
  }
}

export default SkuTable
