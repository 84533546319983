import {observable} from "mobx";
import http from 'libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable cornRecord = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_nickname;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/finance/order/')
        .then(res => this.records = res)
        .finally(() => this.isFetching = false)
  };


}
export default new Store()
