import React from 'react';
import {observer} from 'mobx-react';
import {Table, Modal, message, Divider, Tag} from 'antd';
import {LinkButton} from 'components';
import http from '../../libs/http';
import store from './store';
import styles from "./index.module.css";
import GoodsForm from './Form';


@observer
class GoodsTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '商品编号',
    dataIndex: 'num',
  }, {
    title: '商品分类',
    dataIndex: 'main_type',
  }, {
    title: '商品标题',
    dataIndex: 'title',
  }, {
    title: '商品图片',
    render: text => {
      if (text['title_img']){
        return <div className={styles.img_url}>
          <img src={text['title_img']} alt="thumb" />
        </div>
      }else {
        return ""
      }
    }
  },{
    title: '进货价',
    dataIndex: 'purchasing_cost',
  },{
    title: '市场价',
    dataIndex: 'market_price',
  }, {
    title: '售价',
    dataIndex: 'price',
  },{
    title: '折扣',
    dataIndex: 'discount',
  },{
    title: '运费',
    dataIndex: 'freight'
  },{
    title: '库存',
    dataIndex: 'surplus'
  }, {
    title: '状态',
    render: text => text['is_putaway'] ? <Tag color="#87d068">销售中</Tag> : <Tag color="#f50">已下架</Tag>
  }, {
    title: '操作',
    render: info => (
        <span>
          <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
          <Divider type="vertical"/>
          <LinkButton onClick={() => this.handlePutaway(info)}>{info['is_putaway'] ? '下架' : '上架'}</LinkButton>
        </span>
    )
  }
  ];
  handlePutaway = (text) => {
    Modal.confirm({
      title: '操作确认',
      content: `确定要${text['is_putaway'] ? '下架' : '上架'}【${text['title']}】?`,
      onOk: () => {
        return http.patch(`/api/goods/`, {id: text.id, is_putaway: !text['is_putaway']})
          .then(() => {
            message.success('操作成功');
            store.fetchRecords()
          })
      }
    })
  };
  handleDelete = (text) => {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${text['title']}】?`,
      onOk: () => {
        return http.delete(`/api/goods/${text.id}/`)
            .then(() => {
              message.success('删除成功');
              store.fetchRecords()
            })
      }
    })
  };

  render() {
    let data = store.records;
    if (store.f_title) {
      data = data.filter(item => item['title'].includes(store.f_title))
    }
    if (store.f_status) {
      data = data.filter(item => String(item['is_active']) === store.f_status)
    }
    return (
        <React.Fragment>
          <Table rowKey="id" pagination={{ showTotal: () => `共 ${data.length} 条`}}  loading={store.isFetching} dataSource={data} columns={this.columns}/>
          {store.formVisible && <GoodsForm />}
        </React.Fragment>
    )
  }
}

export default GoodsTable
