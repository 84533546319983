import { makeModuleRoute } from "./libs/router";

import homeRoutes from './pages/home/routes';
import systemRoutes from './pages/system/routes';
import accountRoutes from './pages/account/routes';
import wechatRoutes from './pages/wechat/routes';
import serviceRoutes from './pages/service/routes';
import orderRoutes from './pages/order/routes';
import couponRoutes from './pages/coupon/routes';
import bannerRoutes from './pages/banner/routes';
import goodsRoutes from './pages/goods/routes';
import financeRoutes from './pages/finance/routes';



export default [
  makeModuleRoute('/home', homeRoutes),
  makeModuleRoute('/account', accountRoutes),
  makeModuleRoute('/system', systemRoutes),
  makeModuleRoute('/wechat', wechatRoutes),
  makeModuleRoute('/service', serviceRoutes),
  makeModuleRoute('/order', orderRoutes),
  makeModuleRoute('/coupon', couponRoutes),
  makeModuleRoute('/banner', bannerRoutes),
  makeModuleRoute('/goods', goodsRoutes),
  makeModuleRoute('/finance', financeRoutes),

]
