import React from 'react';
import {observer} from 'mobx-react';
import {Table, Modal, message} from 'antd';
import http from '../../../libs/http';
import store from './store';
import styles from "../user/index.module.css";
import _ from 'lodash'

@observer
class WechatTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '序号',
    key: 'series',
    render: (_, __, index) => index + 1
  }, {
    title: '手机',
    dataIndex: 'mobile',
  },{
    title: '微信昵称',
    dataIndex: 'nickname',
  }, {
    title: '头像',
    render: text => {
      if (text['avatar']){
        return <div className={styles.portrait}>
          <img src={text['avatar']} alt="avatar"/>
        </div>
      }else {
        return ""
      }
    }
  }, {
    title: '用户地区',
    dataIndex: 'region',
  }, {
    title: '注册时间',
    dataIndex: 'create_date'
  },
  ];

  handleDelete = (text) => {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${text['nickname']}】?`,
      onOk: () => {
        return http.delete(`/api/account/user/${text.id}/`)
            .then(() => {
              message.success('删除成功');
              store.fetchRecords()
            })
      }
    })
  };

  render() {
    let data = store.records;
    if (store.f_nickname) {
      // data = data.filter(item => item['nickname'].toLowerCase().includes(store.f_nickname.toLowerCase()))
      data = _.filter(data, {'nickname': store.f_nickname.trim()})
    }
    return (
        <React.Fragment>
          <Table rowKey="id" loading={store.isFetching} dataSource={data} columns={this.columns}/>
        </React.Fragment>
    )
  }
}

export default WechatTable
