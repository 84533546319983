import { observable } from "mobx";
import http from 'libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable detail_record = {};
  @observable isFetching = false;
  @observable formVisible = false;
  @observable detailVisible = false;
  @observable assignVisible = false;
  @observable orderAssignVisible = false;
  @observable shops = [];
  @observable city = [];
  @observable sku = [];

  @observable f_order_no = '';
  @observable f_order_status = '';
  @observable f_order_mobile = '';
  @observable f_mobile = '';

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/order/list/')
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  showDetail = (info = {}) => {
    http.get(`/api/order/list/${info.id}/`).then(res => this.detail_record = res);
    this.detailVisible = true;
  };

  showAyiAssign = (info = {}) => {
    this.detail_record = info;
    this.assignVisible = true;
  };
  orderAssign = (info = {}) => {
    this.orderAssignVisible = true;
    this.detail_record = info;
  };
  hideAyiAssign = () => {
    this.assignVisible = false;

  };


    fetchSku = () => {
      http.get('/api/service/sku/').then(res => this.sku = res)
  };

  showForm = (info = {}) => {
    this.fetchSku();
    this.formVisible = true;
    this.record = info;
  }
}

export default new Store()
