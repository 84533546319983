import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'antd';
import ComForm from './Form';
import store from './store';
import OrderDetail from "./Detail";


@observer
class OrderTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '订单编号',
    dataIndex: 'order_no',
  }, {
    title: '服务名称',
    dataIndex: 'sku_name',
  }, {
    title: '服务地址',
    dataIndex: 'service_addr',
    width: 130,
  }, {
    title: '联系人',
    dataIndex: 'contacts',
  }, {
    title: '电话',
    dataIndex: 'mobile',
  },{
    title: '服务时间',
    dataIndex: 'start_time',
  },  {
    title: '下单时间',
    dataIndex: 'add_time',
  }, {
    title: '订单状态',
    dataIndex: 'status_alias',
  },
    {
      title: '操作',
      width: 150,
      render: info => (
        <span>
        <span className="span-button" onClick={() => store.showDetail(info)}>详情</span>
      </span>
      )
    }
  ];

  render() {
    let data = store.records;
    if (store.f_order_no) {
      data = data.filter(item => item['order_no'].toLowerCase().includes(store.f_order_no.toLowerCase()))
    }
    if (store.f_order_mobile) {
      data = data.filter(item => String(item['mobile']).includes(store.f_order_mobile))
    }
    if (store.f_order_status) {
      data = data.filter(item => String(item['status_alias']).includes(store.f_order_status))
    }
    return (
      <React.Fragment>
        <Table rowKey="id" pagination={{ showTotal: () => `总计 ${data.length} 条`}}  loading={store.isFetching} dataSource={data} columns={this.columns}/>
        {store.formVisible && <ComForm/>}
        {store.detailVisible && <OrderDetail/>}
      </React.Fragment>
    )
  }
}

export default OrderTable
