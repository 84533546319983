import {observable} from "mobx";
import http from 'libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable cornRecord = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_tab ='service_order';

  fetchServiceRecords = () => {
    this.isFetching = true;
    http.get('/api/finance/service/detail/')
        .then(res => this.records = res)
        .finally(() => this.isFetching = false)
  };

  fetchGoodsRecords = () => {
    this.isFetching = true;
    http.get('/api/finance/goods/detail/')
        .then(res => this.records = res)
        .finally(() => this.isFetching = false)
  };

  handleChangeTab = (tab) => {
      if (tab === 'service_order') {
         this.f_tab = 'service_order'
        this.fetchServiceRecords();
      } else {
        this.f_tab = 'goods_order'
        this.fetchGoodsRecords();
      }
  };


}
export default new Store()
