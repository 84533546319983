import React from 'react';
import {Card, Input, Button} from 'antd';
import {SearchForm} from '../../components';
import GoodsTable from './Table';
import store from './store';

export default function () {
  return (
    <Card>
      <SearchForm>
        <SearchForm.Item span={8} title="商品标题" >
          <Input onChange={e => store.f_title = e.target.value} placeholder="请输入标题" allowClear />
        </SearchForm.Item>
        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
      <div style={{marginBottom: 16}}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>添加商品</Button>
      </div>
      <GoodsTable/>
    </Card>
  )
}
