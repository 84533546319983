import React from 'react';
import { observer } from 'mobx-react';
import { Table, Divider } from 'antd';
import http from 'libs/http';
import { blobToExcel } from 'libs/functools';
import ComForm from './Form';
import store from './store';

@observer
class ComTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subLoading: false,
      subData: {}
    }
  }

  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '序号',
    key: 'series',
    render: (_, __, index) => index + 1
  }, {
    title: '标题',
    dataIndex: 'title',
  }, {
    title: '优惠',
    render: info => `${info['type_alias']}/${info['value']}`
  }, {
    title: '总数量',
    dataIndex: 'count',
    render: value => value ? value : '不限'
  }, {
    title: '已兑换',
    dataIndex: 'active_count'
  }, {
    title: '已使用',
    dataIndex: 'use_count'
  }, {
    title: '操作',
    render: info => (
      <span>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
        <Divider type="vertical"/>
        <span className="span-button" onClick={() => this.handleExport(info)}>导出</span>
      </span>
    )
  }];

  handleExport = (info) => {
    http.post('/api/coupon/export/', {id: info.id}, {responseType: 'blob'})
      .then(res => blobToExcel(res.data, `${info.title}兑换码.xlsx`))
  };

  handleExpand = (expanded, record) => {
    const {subData} = this.state;
    if (expanded && !Object.keys(subData).includes(String(record.id))) {
      this.setState({subLoading: true});
      http.get('/api/coupon/records/', {params: {id: record.id}})
        .then(res => this.setState({subData: Object.assign({}, subData, {[record.id]: res})}))
        .finally(() => this.setState({subLoading: false}))
    }
  };

  expandedRowRender = (record) => {
    const columns = [
      {title: '用户', dataIndex: 'username'},
      {title: '兑换时间', dataIndex: 'activated_at'},
      {title: '使用订单', dataIndex: 'order_name'},
      {title: '使用时间', dataIndex: 'used_at'},
    ];
    return <Table
      rowKey="id"
      loading={this.state.subData[record.id] === undefined}
      columns={columns}
      dataSource={this.state.subData[record.id]}
      pagination={false}/>
  };

  render() {
    let data = store.records;
    if (store.f_title) {
      data = data.filter(item => item['title'].toLowerCase().includes(store.f_title.toLowerCase()))
    }
    return (
      <React.Fragment>
        <Table
          rowKey="id"
          loading={store.isFetching}
          dataSource={data}
          columns={this.columns}
          onExpand={this.handleExpand}
          expandedRowRender={this.expandedRowRender}/>
        {store.formVisible && <ComForm/>}
      </React.Fragment>
    )
  }
}

export default ComTable
