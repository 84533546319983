import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, Select, DatePicker, Radio, message } from 'antd';
import skuStore from '../service/sku/store';
import http from 'libs/http';
import store from './store';
import moment from 'moment';

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    if (skuStore.records.length === 0) {
      skuStore.fetchRecords()
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    if (formData['expired_at']) {
      formData['expired_at'] = moment(formData['expired_at'].format('YYYY-MM-DD 23:59:59')).unix()
    }
    http.post('/api/coupon/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        maskClosable={false}
        title={store.record.id ? '编辑优惠券' : '新建优惠券'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="优惠券类型">
            {getFieldDecorator('category', {initialValue: info['category']})(
              <Radio.Group disabled={info.id !== undefined}>
                <Radio.Button value="1">兑换券</Radio.Button>
                <Radio.Button value="2">领取券</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="标题">
            {getFieldDecorator('title', {initialValue: info['title']})(
              <Input placeholder="请输入优惠券标题"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="描述">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input placeholder="请输入优惠券描述"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="类型">
            {getFieldDecorator('type', {initialValue: info['type']})(
              <Select placeholder="请选择">
                <Select.Option value="1">代金券</Select.Option>
                <Select.Option value="2">折扣券</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="面值">
            {getFieldDecorator('value', {initialValue: info['value']})(
              <Input placeholder="请输入面值"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="数量">
            {getFieldDecorator('count', {initialValue: info['count']})(
              <Input disabled={getFieldValue('category') === '1' && info.id !== undefined} placeholder="请输入数量"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="过期时间">
            {getFieldDecorator('expired_at', {initialValue: info['expired_at'] ? moment.unix(info['expired_at']) : undefined})(
              <DatePicker placeholder="默认无限制"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="限定服务">
            {getFieldDecorator('services', {initialValue: info['services']})(
              <Select mode="multiple" placeholder="默认无限定">
                {skuStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)