import {observable} from "mobx";
import http from '../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable detail_record = {};
  @observable city = [];
  @observable isFetching = false;
  @observable formVisible = false;
  @observable detailVisible = false;

  @observable f_num;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/banner/info/')
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  handleDetail = (info = {}) => {
    this.detailVisible = true;
    this.detail_record = info;
  };
  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info;
  }
}

export default new Store()
