import React from 'react';
import {Modal, Form, Input, message, Select} from 'antd';
import http from '../../libs/http';
import store from './store';
import {Upload} from '../../components';


class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching: false,
      clientOptions: [],
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({loading: true});
      let url = '/api/banner/info/';
      if (store.record.id) url += `${store.record.id}/`;
      http.post(url, fieldsValue)
          .then(() => {
            message.success('操作成功');
            store.formVisible = false;
            store.fetchRecords()
          }, () => this.setState({loading: false}))
    });
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
        <Modal
            visible
            maskClosable={false}
            title={store.record.id ? '编辑Banner' : '新增Banner'}
            onCancel={() => store.formVisible = false}
            confirmLoading={this.state.loading}
            onOk={this.handleSubmit}>
          <Form>

            <Form.Item {...itemLayout} required label="Banner名称">
              {getFieldDecorator('name', {initialValue: info['name']})(
                <Input placeholder="请输入Banner名称" />
              )}
            </Form.Item>
            <Form.Item {...itemLayout} required label="Banner分类">
            {getFieldDecorator('type', {initialValue: String(info['type'])})(
              <Select placeholder="请选择Banner分类">
                <Select.Option value="1">首页</Select.Option>
                <Select.Option value="2">商品</Select.Option>
                 <Select.Option value="3">服务</Select.Option>
              </Select>
            )}
          </Form.Item>
            <Form.Item {...itemLayout} label="Banner图片">
              {getFieldDecorator('img_url', {initialValue: info['img_url']})(
                  <Upload maxCount={1} path="banner/" />
              )}
            </Form.Item>

            <Form.Item {...itemLayout} required label="链接地址">
              {getFieldDecorator('img_href', {initialValue: info['img_href']})(
                  <Input  placeholder="请输入Banner链接地址"/>
              )}
            </Form.Item>
            <Form.Item {...itemLayout} label="备注">
              {getFieldDecorator('remark', {initialValue: info['remark']})(
                  <Input  placeholder="请输入备注信息"/>
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create()(BannerForm)
