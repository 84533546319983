import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, Row, Col, Icon, message, Select } from 'antd';
import http from '../../../libs/http';
import store from './store';
import { Upload } from '../../../components';
import styles from './index.module.css';


@observer
class SkuForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching: false,
      clientOptions: [],
      view_price: null,
      view_unit: null
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      if (fieldsValue.unit === '活动') {
        const extra = {};
        extra.view_price = store.extra.view_price;
        extra.view_unit = store.extra.view_unit;
        extra.prices = store.extra.prices.filter(x => x[0] && x[1]);
        extra.fields = store.extra.fields.filter(x => x[0] && x[1]);
        if (!extra.view_price) return message.error('请输入显示价格');
        if (!extra.view_unit) return message.error('请输入显示单位');
        if (extra.prices.length === 0) return message.error('请至少输入一组价格信息');
        fieldsValue['extra'] = extra
      } else {
        if (!fieldsValue.original_price) return message.error('请输入服务原价');
        if (!fieldsValue.view_unit_val) return message.error('请输入显示的价格');
        if (!fieldsValue.unit_val) return message.error('请输入服务单价')
      }
      this.setState({ loading: true });
      let url = '/api/service/sku/';
      if (store.record.id) url += `${store.record.id}/`;
      http.post(url, fieldsValue)
        .then(() => {
          message.success('操作成功');
          store.formVisible = false;
          store.fetchRecords()
        }, () => this.setState({ loading: false }))
    });
  };

  handleUpdate = (e, t, index, index2) => {
    const value = e.target.value;
    if (t === 'p') {
      store.extra['prices'][index][index2] = value
    } else {
      store.extra['fields'][index][index2] = value
    }
  }

  render() {
    const info = store.record;
    const unit = this.props.form.getFieldValue('unit');
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible
        maskClosable={false}
        title={store.record.id ? '编辑服务' : '新增服务'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item required label="服务大类">
            {getFieldDecorator('main_type', { initialValue: info['main_type'] })(
              <Select placeholder="请选择服务大类">
                <Select.Option value="热门服务">热门服务</Select.Option>
                <Select.Option value="保洁服务">保洁服务</Select.Option>
                <Select.Option value="品质推荐">品质推荐</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item required label="服务名称">
            {getFieldDecorator('name', { initialValue: info['name'] })(
              <Input placeholder="请输入服务名称" />
            )}
          </Form.Item>
          <Form.Item label="服务主图">
            {getFieldDecorator('title_img', { initialValue: info['title_img'] })(
              <Upload maxCount={1} path="service/" />
            )}
          </Form.Item>
          <Form.Item required label="定价类型">
            {getFieldDecorator('unit', { initialValue: info['unit'] })(
              <Select placeholder="请选择定价类型">
                <Select.Option value="平米">平米</Select.Option>
                <Select.Option value="间">间</Select.Option>
                <Select.Option value="台">台</Select.Option>
                <Select.Option value="4小时">4小时</Select.Option>
                <Select.Option value="起">起</Select.Option>
                <Select.Option value="活动">活动</Select.Option>
              </Select>
            )}
          </Form.Item>
          {unit === '活动' ? (
            <React.Fragment>
              <Form.Item required label="显示价格">
                <Row gutter={8}>
                  <Col span={12}>
                    <Input value={store.extra.view_price}
                      onChange={e => store.extra.view_price = e.target.value}
                      placeholder="请输入显示价格" />
                  </Col>
                  <Col span={12}>
                    <Input value={store.extra.view_unit}
                      onChange={e => store.extra.view_unit = e.target.value}
                      placeholder="请输入显示单位" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item required label="价格信息">
                {store.extra.prices.map(([k, v], index) => (
                  <Row gutter={8} key={index}>
                    <Col span={12}>
                      <Input value={k} placeholder="请输入名称" onChange={e => this.handleUpdate(e, 'p', index, 0)} />
                    </Col>
                    <Col span={12}>
                      <Input value={v} placeholder="请输入价格" onChange={e => this.handleUpdate(e, 'p', index, 1)} />
                    </Col>
                    {index === 0 ? (
                      <Icon
                        type="plus-circle-o"
                        className={styles.actionIcon}
                        onClick={() => store.extra.prices.push([])} />
                    ) : (
                        <Icon
                          type="minus-circle-o"
                          className={styles.actionIcon}
                          onClick={() => store.extra.prices.splice(index, 1)} />
                      )}
                  </Row>
                ))}
              </Form.Item>
              <Form.Item label="额外字段">
                {store.extra.fields.map(([k, v], index) => (
                  <Row gutter={8} key={index}>
                    <Col span={12}>
                      <Input value={k} placeholder="请输入字段名称" onChange={e => this.handleUpdate(e, 'e', index, 0)} />
                    </Col>
                    <Col span={12}>
                      <Input value={v} placeholder="请输入字段取值" onChange={e => this.handleUpdate(e, 'e', index, 1)} />
                    </Col>
                    {index === 0 ? (
                      <Icon
                        type="plus-circle-o"
                        className={styles.actionIcon}
                        onClick={() => store.extra.fields.push([])} />
                    ) : (
                        <Icon
                          type="minus-circle-o"
                          className={styles.actionIcon}
                          onClick={() => store.extra.fields.splice(index, 1)} />
                      )}
                  </Row>
                ))}
              </Form.Item>
            </React.Fragment>
          ) : (
              <React.Fragment>
                <Form.Item required label="服务原价">
                  {getFieldDecorator('original_price', { initialValue: info['original_price'] })(
                    <Input placeholder="请输入服务原价" />
                  )}
                </Form.Item>
                <Form.Item required label="服务单价">
                  {getFieldDecorator('unit_val', { initialValue: info['unit_val'] })(
                    <Input placeholder="请输入服务单价" />
                  )}
                </Form.Item>

              </React.Fragment>
            )}
          <Form.Item required label="显示单价">
            {getFieldDecorator('view_unit_val', { initialValue: info['view_unit_val'] })(
              <Input placeholder="请输入显示的服务单价" />
            )}
          </Form.Item>
          <Form.Item required label="详情主图">
            {getFieldDecorator('detail_img', { initialValue: info['detail_img'] })(
              <Upload maxCount={1} path="service/" />
            )}
          </Form.Item>
          <Form.Item required label="详情多图">
            {getFieldDecorator('sku_detail', { initialValue: info['sku_detail'] })(
              <Upload maxCount={13} path="service/" />
            )}
          </Form.Item>
          <Form.Item label="备注">
            {getFieldDecorator('remark', { initialValue: info['remark'] })(
              <Input placeholder="请输入备注信息" />
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(SkuForm)
