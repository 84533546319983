import React from 'react';
import { observer } from 'mobx-react';
import { Button, Select } from 'antd';
import { SearchForm, AuthCard } from 'components';
import SkuTable from './Table';
import store from './store';

@observer
class Index extends React.Component {
  render() {
    return (
      <AuthCard>
        <SearchForm>
          <SearchForm.Item span={5} title="服务大类">
            <Select allowClear onChange={v => store.f_main_type = v} placeholder="请选择">
              <Select.Option value="热门服务">热门服务</Select.Option>
              <Select.Option value="保洁服务">保洁服务</Select.Option>
              <Select.Option value="品质推荐">品质推荐</Select.Option>
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={6}>
            <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
          </SearchForm.Item>
        </SearchForm>
        <div style={{marginBottom: 16}}>
          <Button type="primary" icon="plus" onClick={() => store.showForm()}>新增服务</Button>
        </div>
        <SkuTable/>
      </AuthCard>
    )
  }
}

export default Index
