import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import store from './store';

@observer
class Qrcode extends React.Component {

  render() {
    return (
      <Modal
        visible
        maskClosable={false}
        title={`${store.record.name} - 二维码`}
        onCancel={() => store.qrcodeVisible = false}
        footer={null}>
        <img src={`/api/service/sku/${store.record.id}/?x-token=${localStorage.getItem('token')}`} alt="qrcode"/>
      </Modal>
    )
  }
}

export default Qrcode

