import {observable} from "mobx";
import http from '../../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable cornRecord = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_nickname;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/wechat/user/')
        .then(res => this.records = res)
        .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.record = info;
    http.get(`/api/wechat/user/${info.id}/`).then(res => {
      this.cornRecord = res;
      console.log('cornRecord', this.cornRecord );
      this.formVisible = true;
    })
  };

}
export default new Store()
