import React from 'react';
import {Card, Descriptions, Modal, Tag} from 'antd';
import store from './store';


class SkuDetail extends React.Component {
    render() {
        const info = store.detail_record;
        return (
            <Modal
            visible
            width="85%"
            maskClosable={false}
            title={'SKU服务详情'}
            onCancel={() => store.detailVisible = false}
            onOk={() => store.detailVisible = false}>
            <div>
                <Card>
                    <Descriptions  title="基本信息">
                        <Descriptions.Item label="服务城市">{info['city_name']}</Descriptions.Item>
                        <Descriptions.Item label="服务大类"> {info['main_type']} </Descriptions.Item>
                        <Descriptions.Item label="服务名称"> {info['name']} </Descriptions.Item>
                        <Descriptions.Item label="创建时间">{info['add_time']}</Descriptions.Item>
                        <Descriptions.Item label="服务单位">{info['unit']}</Descriptions.Item>
                        <Descriptions.Item label="服务原价">{info['original_price']}</Descriptions.Item>
                        <Descriptions.Item label="服务单价">{info['unit_val']}</Descriptions.Item>
                        <Descriptions.Item label="状态">
                            {info['status'] ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">已下架</Tag>}
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <hr style={{border:"1px double #e8e8e8"}} />
                    <br />
                    <Descriptions  title="图片信息">
                        <Descriptions.Item label="服务标题图片">
                            {/*{ <img src= {info['title_img']} > }*/}
                            {info['title_img']}
                            </Descriptions.Item>
                        <Descriptions.Item label="服务详情图片"> {info['sku_detail']} </Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>
            </Modal>
        )
    }
}

export default SkuDetail

