import React from 'react';
import { Card, Input, Button, Select } from 'antd';
import { SearchForm } from 'components';
import GoodsOrderTable from './Table';
import store from './store';

export default function () {
  return (
    <Card>
      <SearchForm>
        <SearchForm.Item span={6} title="订单号">
          <Input onChange={e => store.f_order_no = e.target.value} placeholder="请输入订单号" allowClear />
        </SearchForm.Item>
          <SearchForm.Item span={6} title="订单电话">
          <Input onChange={e => store.f_order_mobile = e.target.value} placeholder="请输入订单联系人电话" allowClear/>
        </SearchForm.Item>
        <SearchForm.Item span={6} title="订单状态">
          <Select allowClear onChange={s => store.f_order_status = s}>
          <Select.Option value="待付款" >待付款</Select.Option>
          <Select.Option value="待确认" >待确认</Select.Option>
          <Select.Option value="待发货" >待发货</Select.Option>
          <Select.Option value="已完成" >已完成</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item span={6}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
      {/* <div style={{marginBottom: 16}}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>添加订单</Button>
      </div> */}
      <GoodsOrderTable/>
    </Card>
  )
}
