import React from 'react';
import {Input, Button, Select} from 'antd';
import {SearchForm, AuthCard} from 'components';
import OverViewTable from './Table';
import store from './store';

export default function () {
  return (
    <AuthCard>
      <SearchForm>
        <SearchForm.Item span={8} title="订单类型">
          <Input onChange={e => store.f_nickname = e.target.value} placeholder="订单类型" allowClear />
        </SearchForm.Item>
        
        <SearchForm.Item span={8} title="订单类型">
          <Select placeholder="请选择" onChange={v => store.f_type = v} allowClear >
          <Select.Option key="服务订单" value="服务订单">服务订单</Select.Option>
          <Select.Option key="商品订单" value="商品订单">商品订单</Select.Option>
          </Select>
        </SearchForm.Item>

        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>

      <OverViewTable/>
    </AuthCard>
  )
}
