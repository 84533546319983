import React from 'react';
import {Card, Button, Select} from 'antd';
import {SearchForm} from '../../components';
import SkuTable from './Table';
import store from './store';

export default function () {
  return (
    <Card>
      <SearchForm>
          <SearchForm.Item span={8} title="Banner状态">
          <Select allowClear onChange={v => store.f_status = v} placeholder="请选择">
            <Select.Option value="true">正常</Select.Option>
            <Select.Option value="false">已下架</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item span={8}>
          <Button type="primary" icon="sync" onClick={store.fetchRecords}>刷新</Button>
        </SearchForm.Item>
      </SearchForm>
      <div style={{marginBottom: 16}}>
        <Button type="primary" icon="plus" onClick={() => store.showForm()}>新增Banner</Button>
      </div>
      <SkuTable/>
    </Card>
  )
}
