import React from 'react';
import {Form, Input, message, Modal} from 'antd';
import http from '../../libs/http';
import store from './store';
import {observer} from "mobx-react/index";
import {Upload} from "../../components";


@observer
class GoodsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((error, fieldsValue) => {
      if (!error) {
        this.setState({loading: true});
        const values = {
          ...fieldsValue,
        };
        values['id'] = store.record.id;
        http.post('/api/goods/', values)
          .then(() => {
            message.success('操作成功');
            store.formVisible = false;
            store.fetchRecords()
          }, () => this.setState({loading: false}))
      }
    });
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible width="55%"
        maskClosable={false}
        title={store.record.id ? '编辑商品' : '新建商品'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="品牌名称">
            {getFieldDecorator('brand', {initialValue: info['brand']})(
              <Input placeholder="请输入品牌名称"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品分类">
            {getFieldDecorator('main_type', {initialValue: info['main_type']})(
              <Input placeholder="请输入商品分类"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品标题">
            {getFieldDecorator('title',
              {initialValue: info['title']})(
              <Input placeholder="请输入商品标题"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="进货价格">
            {getFieldDecorator('purchasing_cost',
              {initialValue: info['purchasing_cost']})(
              <Input placeholder="请输入进货成本价格"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="京东价格">
            {getFieldDecorator('jd_price',
              {initialValue: info['jd_price']})(
              <Input placeholder="请输入京东价格"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="市场价格">
            {getFieldDecorator('market_price',
              {initialValue: info['market_price']})(
              <Input placeholder="请输入市场价格"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} required label="折扣">
            {getFieldDecorator('discount',
              {initialValue: info['discount']})(
              <Input placeholder="请输入折扣"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} required label="折扣金额">
            {getFieldDecorator('discount_cost',
              {initialValue: info['discount_cost']})(
              <Input placeholder="请输入折扣金额"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品售价">
            {getFieldDecorator('price',
              {initialValue: info['price']})(
              <Input placeholder="请输入商品售价"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="积分价格">
            {getFieldDecorator('coin',
              {initialValue: info['coin']})(
              <Input placeholder="请输入积分价格"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="运费">
            {getFieldDecorator('freight',
              {initialValue: info['freight']})(
              <Input placeholder="请输入运费，0是包邮"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="库存">
            {getFieldDecorator('surplus',
              {initialValue: info['surplus']})(
              <Input placeholder="请输入库存数量"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品首页图片">
            {getFieldDecorator('title_img', {initialValue: info['title_img']})(
              <Upload maxCount={1} path="goods"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品详情Banner图片">
            {getFieldDecorator('banner_img', {initialValue: info['banner_img']})(
              <Upload maxCount={1} path="goods"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品详情描述">
            {getFieldDecorator('detail', {initialValue: info['detail']})(
              <Input.TextArea rows={4} placeholder="请输入商品详情描述信息"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品详情图">
            {getFieldDecorator('detail_img', {initialValue: info['detail_img']})(
              <Upload maxCount={0} path="goods"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout}  label="备注信息">
            {getFieldDecorator('remark',
              {initialValue: info['remark']})(
              <Input placeholder="请输入库备注信息"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
export default Form.create()(GoodsForm)
