import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'antd';
import ComForm from './Form';
import store from './store';
import GoodsOrderDetail from "./Detail";


@observer
class GoodsOrderTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '订单编号',
    dataIndex: 'order_no',
  }, {
    title: '商品标题',
    dataIndex: 'title',
  }, {
    title: '优惠券支付',
    dataIndex: 'contacts',
  }, {
    title: '实际支付',
    dataIndex: 'actual_price',
    width: 130,
  }, {
    title: '购买数量',
    dataIndex: 'count',
  }, {
    title: '下单时间',
    dataIndex: 'add_time',
  }, {
    title: '订单状态',
    dataIndex: 'status_alias',
  },{
      title: '操作',
      width: 150,
      render: info => (
        <span>
        <span className="span-button" onClick={() => store.showDetail(info)}>详情</span>

      </span>
      )
    }
  ];

  render() {
    let data = store.records;
    if (store.f_order_no) {
      data = data.filter(item => item['order_no'].toLowerCase().includes(store.f_order_no.toLowerCase()))
    }
    if (store.f_order_mobile) {
      data = data.filter(item => String(item['mobile']).includes(store.f_order_mobile))
    }
    if (store.f_order_status) {
      data = data.filter(item => String(item['status_alias']).includes(store.f_order_status))
    }
    return (
      <React.Fragment>
        <Table rowKey="id" pagination={{ showTotal: () => `总计 ${data.length} 条`}}  loading={store.isFetching} dataSource={data} columns={this.columns}/>
        {store.formVisible && <ComForm/>}
        {store.detailVisible && <GoodsOrderDetail/>}
      </React.Fragment>
    )
  }
}

export default GoodsOrderTable
