import React from 'react';
import {AuthCard} from 'components';
import DetailTable from './Table';

export default function () {
  return (
    <AuthCard>
      
      <DetailTable/>
    </AuthCard>
  )
}
