import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tabs, Button} from 'antd';
import store from './store';
import { blobToExcel } from 'libs/functools';
import {http} from 'libs'

@observer
class DetailTable extends React.Component {
  componentDidMount() {
    store.fetchServiceRecords()
  }

  handleExport = () => {
    let title = null;
    if (store.f_tab ==='service_order') {
       title = '服务订单对账单.xlsx'
    }
    else {
       title = '商品订单对账单.xlsx'
    }
    http.post('/api/finance/export/', {order_type: store.f_tab}, {responseType: 'blob'})
      .then(res => blobToExcel(res.data, `${title}`))
  };

  render() {
    let data = store.records;
    return (
        <React.Fragment>
        <Tabs onChange={store.handleChangeTab} 
        type="card" 
        tabBarExtraContent= {<Button onClick={this.handleExport}>导出</Button>}
        defaultActiveKey='service_order'>
            <Tabs.TabPane tab="服务订单" key="service_order">
              <Table rowKey="id" 
              loading={store.isFetching} 
              dataSource={data} 
              pagination={{
                showSizeChanger: true,
                showLessItems: true,
                hideOnSinglePage: true,
                showTotal: total => `共 ${total} 条`,
                pageSizeOptions: ['10', '20', '50', '100']
              }}>
              <Table.Column title='服务名称' dataIndex='sku_name' />
              <Table.Column title='支付金额(元)' dataIndex='actual_price' />
              {/* <Table.Column title='流水号' dataIndex='escrow_trade_no' /> */}
              <Table.Column title='服务时间' dataIndex='start_time' />
              <Table.Column title='下单时间' dataIndex='add_time' />
              <Table.Column title='服务地址' dataIndex='service_addr' />
              <Table.Column title='联系人' dataIndex='contacts' />
              <Table.Column title='联系电话' dataIndex='mobile' />
              <Table.Column title='订单状态' dataIndex='status_alias' />
              </Table>
            </Tabs.TabPane>

        <Tabs.TabPane tab="商品订单" key="goods_order">
              <Table rowKey="id" 
              loading={store.isFetching} 
              dataSource={data} 
              pagination={{
                showSizeChanger: true,
                showLessItems: true,
                hideOnSinglePage: true,
                showTotal: total => `共 ${total} 条`,
                pageSizeOptions: ['10', '20', '50', '100']
              }}>
              <Table.Column title='商品名称' dataIndex='goods_title' />
              {/* <Table.Column title='商品单价' dataIndex='goods_unit_val' /> */}
              {/* <Table.Column title='下单数量' dataIndex='count' /> */}
              <Table.Column title='支付金额(元)' dataIndex='actual_price' />
              {/* <Table.Column title='流水号' dataIndex='escrow_trade_no' /> */}
              <Table.Column title='下单时间' dataIndex='add_time' />
              <Table.Column title='收货地址' dataIndex='home_addr' />
              <Table.Column title='联系人' dataIndex='contacts' />
              <Table.Column title='联系电话' dataIndex='mobile' />
              <Table.Column title='订单状态' dataIndex='status_alias' />
              </Table>
          </Tabs.TabPane>
        </Tabs>
        </React.Fragment>
    )
  }
}

export default DetailTable
