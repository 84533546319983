import {observable} from "mobx";
import http from '../../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable detail_record = {};
  @observable extra = {fields: [[]], prices: [[]]};
  @observable isFetching = false;
  @observable formVisible = false;
  @observable qrcodeVisible = false;
  @observable detailVisible = false;

  @observable f_main_type;
  @observable f_channel;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/service/sku/')
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };
  handleDetail = (info = {}) => {
    this.detailVisible = true;
    this.detail_record = info;
  };
  showForm = (info = {}) => {
    this.formVisible = true;
    if (info.extra) {
      this.extra = info.extra
    } else {
      this.extra = {fields: [[]], prices: [[]]}
    }
    this.record = info;
  };
  showQrcode = (info) => {
    this.record = info;
    this.qrcodeVisible = true
  };


}

export default new Store()
