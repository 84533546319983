import React from "react";
import { observer } from "mobx-react";
import { Table, Divider, Modal, message, Tag } from "antd";
import SkuForm from "./Form";
import SkuDetail from "./Detail";
import Qrcode from './Qrcode';
import http from "../../../libs/http";
import store from "./store";

@observer
class SkuTable extends React.Component {
  componentDidMount() {
    store.fetchRecords();
  }

  columns = [
    {
      title: "服务大类",
      render: (text) => {
        if (text["main_type"] === "保洁服务") {
          return <Tag color="blue">保洁服务</Tag>;
        } else if (text["main_type"] === "热门服务") {
          return <Tag color="magenta">热门服务</Tag>;
        }  else {
          return <Tag>{text["main_type"]}</Tag>;
        }
      },
    },
    {
      title: "服务名称",
      dataIndex: "name",
    },
    {
      title: "服务单位",
      dataIndex: "unit",
    },
    {
      title: "单价",
      dataIndex: "unit_val",
    },
    {
      title: "创建时间",
      dataIndex: "add_time",
    },
    {
      title: "状态",
      render: (text) => {
        if (text["status"]) {
          return (
            <Tag
              color="#87d068"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleStatus(text)}
            >
              正常
            </Tag>
          );
        } else {
          return (
            <Tag
              color="#f50"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleStatus(text)}
            >
              已下架
            </Tag>
          );
        }
      },
    },
    {
      title: "操作",
      render: (info) => (
        <span>
          <span className="span-button" onClick={() => store.showForm(info)}>
            编辑
          </span>
          <Divider type="vertical" />
          <span
            className="span-button"
            onClick={() => store.handleDetail(info)}
          >
            详细
          </span>
          <Divider type="vertical" />
          <span
            className="span-button"
            onClick={() => store.showQrcode(info)}
          >
            二维码
          </span>
        </span>
      ),
    },
  ];

  handleDelete = (text) => {
    Modal.confirm({
      title: "删除确认",
      content: `确定要删除【${text["name"]}】?`,
      onOk: () => {
        return http.delete(`/api/service/sku/${text.id}/`).then(() => {
          message.success("删除成功");
          store.fetchRecords();
        });
      },
    });
  };

  handleStatus = (text) => {
    Modal.confirm({
      title: "修改状态",
      content: `确定要修改【${text["name"]}】的状态?`,
      onOk: () => {
        text["status"] = !text["status"];
        return http.put(`/api/service/sku/${text.id}/`, text).then(() => {
          message.success("状态修改成功");
          store.fetchRecords();
        });
      },
    });
  };

  render() {
    let data = store.records;
    if (store.f_main_type) {
      data = data.filter((item) =>
        item["main_type"].includes(store.f_main_type.toLowerCase())
      );
    }
    if (store.f_city_id) {
      data = data.filter((item) => item["city_id"] === store.f_city_id);
    }

    return (
      <React.Fragment>
        <Table
          rowKey="id"
          loading={store.isFetching}
          dataSource={data}
          columns={this.columns}
        />
        {store.formVisible && <SkuForm />}
        {store.detailVisible && <SkuDetail />}
        {store.qrcodeVisible && <Qrcode/>}
      </React.Fragment>
    );
  }
}

export default SkuTable;
