import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tabs} from 'antd';
import store from './store';
import _ from 'lodash'

function CommTable(props) {
  return (
    <Table rowKey="id" 
    loading={store.isFetching} 
    dataSource={props.data} >
      <Table.Column title='日期' dataIndex='month' />
      <Table.Column title='订单类型' dataIndex='order_type' />
      <Table.Column title='收入(元)' dataIndex='income' />
      <Table.Column title='支出(元)' dataIndex='expend' />
      <Table.Column title='操作' />
    </Table>)
};

@observer
class OverViewTable extends React.Component {


  componentDidMount() {
    store.fetchRecords()
  }



  handleChangeTab = () => {

  };

  render() {
    let data = store.records;
    if (store.f_nickname) {
      // data = data.filter(item => item['nickname'].toLowerCase().includes(store.f_nickname.toLowerCase()))
      data = _.filter(data, {'nickname': store.f_nickname.trim()})
    }
    return (
        <React.Fragment>
          <Tabs onChange={this.handleChangeTab} type="card">
            <Tabs.TabPane tab="日汇总" key="1">
              <CommTable data={data} />
            </Tabs.TabPane>

          <Tabs.TabPane tab="月汇总" key="2">
            <CommTable />
          </Tabs.TabPane>
          </Tabs>
        </React.Fragment>
    )
  }
}

export default OverViewTable
