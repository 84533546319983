import React from 'react';
import { observer } from 'mobx-react'
import { Card, Descriptions, Modal, Tag } from 'antd';
import store from './store';
import lds from 'lodash'

@observer
class OrderDetail extends React.Component {
    render() {
        const info = store.detail_record;
        return (
            <Modal
                visible
                width="65%"
                maskClosable={false}
                title={'订单详情'}
                onCancel={() => store.detailVisible = false}
                onOk={() => store.detailVisible = false}>
                <div>
                    <Card>
                        <Descriptions title="基本信息">
                            <Descriptions.Item label="订单号">{info['order_no']}</Descriptions.Item>
                            <Descriptions.Item label="下单时间">{info['add_time']}</Descriptions.Item>
                            <Descriptions.Item label="状态">
                                <Tag color="#87d068">{info['status_alias']}</Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        <br />
                        <hr style={{ border: "1px double #e8e8e8" }} />
                        <br />
                        <Descriptions title="服务信息">
                            <Descriptions.Item label="服务名称"> {info['sku_name']} </Descriptions.Item>
                            <Descriptions.Item label="服务单位">{info['sku_unit']}</Descriptions.Item>
                            <Descriptions.Item label="服务数量"> {info['count']} </Descriptions.Item>
                            <Descriptions.Item label="服务城市">{info['city_name']}</Descriptions.Item>
                            <Descriptions.Item label="服务时间">
                                <Tag color="#108ee9">{info['start_time']}</Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="服务地址">{info['service_addr']}</Descriptions.Item>
                            <Descriptions.Item label="联系人">{info['contacts']}</Descriptions.Item>
                            <Descriptions.Item label="联系人电话">{info['mobile']}</Descriptions.Item>
                            <Descriptions.Item label="订单备注">{info['remark']}</Descriptions.Item>
                        </Descriptions>
                        <br />
                        <hr style={{ border: "1px double #e8e8e8" }} />
                        <br />
                        <Descriptions title="支付信息">
                            <Descriptions.Item label="订单金额"> {info['sku_unit_val']} </Descriptions.Item>
                            <Descriptions.Item label="优惠券支付"> {info['coupon_name']} </Descriptions.Item>
                            <Descriptions.Item label="支付金额"> {info['actual_price']} </Descriptions.Item>
                            {lds.get(info, 'extra_pay', []).map((item,key) => ( 
                            <Descriptions.Item key={key} label="差价/尾款金额"> {item['money']} </Descriptions.Item>
                             ))}
                            <Descriptions.Item label="支付流水号"> {info['escrow_trade_no']} </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </div>
            </Modal>
        )
    }
}

export default OrderDetail

