import React from 'react';
import {observer} from 'mobx-react'
import {Modal, Form, Input, message, Select, DatePicker, Row, Col} from 'antd';
import http from 'libs/http';
import store from './store';

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    formData['start_time'] = formData['start_time'] ? formData['start_time'].format('YYYY-MM-DD HH:mm') : '';
    http.post('/api/order/list/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width="60%"
        maskClosable={false}
        title={store.record.id ? '编辑订单' : '添加订单'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item {...itemLayout} required label="服务类别">
                {getFieldDecorator('sku_id', {initialValue: info['sku_id']})(
                  <Select placeholder="请选择服务类别">
                    {store.sku.map(item => (
                      <Select.Option key={item.id} value={item.id}> {item['main_type'] } - {item.name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="服务单位">
                {getFieldDecorator('sku_unit', {initialValue: info['sku_unit']})(
                  <Select allowClear placeholder="请选择">
                    <Select.Option value="小时">小时</Select.Option>
                    <Select.Option value="平米">平米</Select.Option>
                    <Select.Option value="间">间</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="服务数量">
                {getFieldDecorator('count', {initialValue: info['count']})(
                  <Input placeholder="请输入服务的总数量，如果是4小时就输入4，如果1间就输入1"/>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="订单价格">
                {getFieldDecorator('actual_price', {initialValue: info['actual_price']})(
                  <Input placeholder="请输入订单价格"/>
                )}
              </Form.Item>
              <Form.Item {...itemLayout}  label="订单备注">
                {getFieldDecorator('remark', {initialValue: info['remark']})(
                  <Input placeholder="请输入订单备注"/>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...itemLayout} required label="客户姓名">
                {getFieldDecorator('contacts', {initialValue: info['contacts']})(
                  <Input placeholder="请输入客户姓名"/>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="客户电话">
                {getFieldDecorator('mobile', {initialValue: info['mobile']})(
                  <Input placeholder="请输入客户电话"/>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="服务地址">
                {getFieldDecorator('service_addr', {initialValue: info['service_addr']})(
                  <Input placeholder="请输入服务地址"/>
                )}
              </Form.Item>
              <Form.Item {...itemLayout} required label="服务时间">
                {getFieldDecorator('start_time', {initialValue: info['start_time']})(
                  <DatePicker format="YYYY-MM-DD HH:mm" showTime placeholder="请输入服务时间"/>
                )}
              </Form.Item>
             
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)
